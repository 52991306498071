/**
 * @file standalone/sd_product.scss
 *
 * \brief Library file - shared styles for stardust product pages
 *
*/

// 2020 Redesign
// Base library for shared styles for the new sd product pages.
// Lib is attached per template as needed.

@import '../theme-bootstrap';

// Simplified Star Ratings
$ratingSize: 18px;
$ratingWidth: 95px;

.elc-grid-container,
.elc-grid-item-product {
  .elc-product-full-shades-dropdown-component,
  .elc-shades-dropdown-component {
    order: 5;
    & ~ .elc-product-prices-clickable-wrapper {
      margin: 0 auto;
      width: auto;
      order: 3;
      @include breakpoint($landscape-up) {
        @include swap_direction(margin, -40px 40% 0 20%);
      }
    }
    .elc-dropdown-readonly-input,
    .elc-dropdown-option {
      color: $color-black;
      font-family: $base-font-family;
      text-transform: uppercase;
    }
    .elc-dropdown-readonly-input,
    .elc-dropdown-arrow {
      border: none;
    }
    .elc-dropdown-arrow,
    .elc-dropdown-arrow:hover {
      background: none;
    }
    .elc-dropdown-readonly-input {
      justify-content: flex-start;
    }
    .elc-arrow-icon {
      background: url(/sites/glamglowmud/themes/gg_base/img/icons/src/arrow_black.svg) no-repeat 50% 50%;
      width: 14px;
      height: 14px;
      -webkit-mask: none;
      transform: rotate(-90deg);
    }
    .elc-shade-image:hover {
      box-shadow: rgb(1, 1, 1) 0 0 0 1px;
    }
    div[aria-expanded='true'] {
      .elc-arrow-icon {
        transform: rotate(90deg);
      }
    }
  }
  .elc-product {
    &-badge-wrapper {
      font-size: 14px;
      letter-spacing: 1px;
      line-height: 22px;
      color: $cr19-dark-pink;
      font-family: $header-font-bold;
      font-weight: 100;
      text-transform: uppercase;
      z-index: 1;
      position: absolute;
      top: 37px;
      #{$ldirection}: 20px;
      height: 20px;
      padding: 2px;
      box-sizing: border-box;
      align-items: center;
      display: flex;
    }
    &-overview-description p,
    &-description-wrapper {
      text-align: center;
      font-size: 14px;
      letter-spacing: 1px;
      color: $color-black;
      font-family: $base-font-family;
    }
    &-shade-picker {
      order: 4;
      text-align: center;
      margin: 0;
    }
    &-prices-wrapper,
    &-prices-clickable-wrapper {
      width: 100%;
      .elc-product-price-row-wrapper {
        justify-content: center;
      }
      .elc-product-original-price,
      .elc-product-price,
      .elc-product-price-range {
        font-size: 14px;
        letter-spacing: 0;
        line-height: 22px;
        color: $color-black;
        font-family: $base-font-family;
        font-weight: bold;
        text-align: center;
        margin: 0;
      }
    }
    &-price {
      color: $color-red;
    }
    &-ingredients {
      order: 8;
      margin: 15px auto;
    }
  }
  .elc-size-picker {
    &-container {
      order: 4;
      width: 100%;
      @include breakpoint($landscape-up) {
        order: 6;
      }
      .slick-slider {
        margin: 0;
      }
    }
    &-wrapper {
      div:last-child {
        justify-content: center;
        text-align: center;
      }
    }
    &-box,
    &-box-selected {
      font-size: 14px;
      letter-spacing: 0;
      line-height: 22px;
      height: 40px;
    }
    &-box {
      border: 1px solid $color-light-gray;
    }
  }
  .elc-rating-component {
    width: 100%;
    order: 2;
    top: 4%;
    @include breakpoint($medium-up) {
      position: relative;
      margin-top: 0;
      transform: none;
      margin: 0 auto;
      width: auto;
      top: 0;
    }
    .elc-product-rating-wrapper {
      justify-content: center;
    }
    .elc-star-icon {
      width: 19px;
      height: 18px;
    }
    .elc-rating-reviews {
      margin: 3px 0;
    }
    .elc-reviews-number {
      font-size: 16px;
      letter-spacing: 0;
      color: $color-black;
      font-family: $base-font-family;
      font-weight: normal;
    }
  }
  .elc-selected-size-label,
  .elc-selected-size,
  .elc-product-overview-title,
  .elc-read-reviews,
  .elc-dropdown-icon-container,
  .elc-average-rating-text,
  div[class*='DropdownItem__IconWrapper'] {
    display: none;
  }
  .elc-slider-dot-button {
    background: none;
    @include breakpoint($medium-up) {
      display: none;
    }
    &:before {
      background: none;
      border-radius: 50%;
      border: 2px solid $color-black;
      color: $color-black;
      font-size: 0;
      height: 12px;
      opacity: unset;
      width: 12px;
      @include breakpoint($medium-up) {
        border: 1px solid $color-black;
      }
    }
    &-active {
      &:before {
        background: $color-black;
      }
    }
  }
  .elc-product-cta-button-wrapper {
    div div .elc-product-notify-me-button {
      background: none;
      background-color: $color-black;
      color: $color-white;
      &:hover {
        background: none;
        background-color: $color-lightgray;
        color: $color-black;
      }
    }
  }
}

.elc-carousel {
  &-section-wrapper {
    margin: 150px auto 0;
    max-width: 100%;
    position: relative;
    @include breakpoint($medium-up) {
      margin: 0;
    }
    .elc-slider-arrow-wrapper {
      &:before {
        display: none;
      }
    }
  }
  &-products {
    .slick-slide,
    .elc-product-images-wrapper,
    .elc-product-images-wrapper .elc-img {
      @include breakpoint($xxlarge-up) {
        width: 514px;
        height: 514px;
      }
    }
  }
  &-thumbnails {
    margin: 18px 0;
    .elc-img {
      border: 3px solid transparent;
    }
    .slick-slide {
      padding-bottom: 0;
      div {
        padding: 0;
        &:focus {
          outline: none;
        }
      }
    }
    .slick-current {
      .elc-img {
        border: 3px solid $color-dark-pink;
      }
    }
    .slick-prev {
      #{$ldirection}: auto;
    }
  }
}

// GRID and CROSS-SELL
.elc-grid-container {
  .elc-product-brief-wrapper {
    box-shadow: none;
    padding: 0;
    @include breakpoint($medium-up) {
      padding: 15px;
    }
    &:hover {
      box-shadow: none;
    }
    .elc-product-shade-picker-wrapper {
      margin-top: -33px;
      height: 40px;
      .slick-slider {
        margin-bottom: 0;
      }
      .slick-track {
        margin: 0 auto;
        display: flex;
        justify-content: center;
        .slick-slide {
          width: auto !important;
          margin-#{$rdirection}: 5px;
          &:last-child {
            margin-#{$rdirection}: 0;
          }
        }
      }
      p {
        font-family: $header-font-bold;
        font-size: 14px;
        color: $color-black;
        text-transform: uppercase;
      }
    }
    .elc-product-price-wrapper {
      .elc-product-original-price,
      .elc-product-price,
      .elc-product-price-range {
        font-size: 16px;
        font-family: $header-font-bold;
        text-align: center;
      }
      .elc-product-original-price {
        color: $color-black;
      }
      .elc-product-price {
        color: $color-red;
      }
    }
    .elc-product-size-wrapper {
      margin-top: 10px;
      .slick-slider {
        margin-bottom: 0;
      }
      .slick-track {
        flex-wrap: wrap;
      }
      .slick-slide {
        width: auto !important;
      }
      .elc-slider-arrow-wrapper {
        &:before {
          display: none;
        }
      }
    }
    .elc-product-rating-wrapper {
      & > div {
        justify-content: center;
      }
      .elc-rating {
        margin: 4px 0 14px;
      }
      .elc-read-reviews {
        display: none;
      }
      .elc-reviews-number {
        font-family: inherit;
        font-size: 16px;
        color: $color-black;
        font-weight: normal;
      }
      .elc-star-icon {
        width: 14px;
        height: 14px;
      }
    }
    .elc-size-picker-wrapper {
      font-family: inherit;
      font-size: 14px;
      line-height: 1em;
      > div:nth-child(2) {
        flex-wrap: wrap;
      }
      div[class*='ProductSizePicker__SpaceRow'] {
        display: none;
      }
      div[class*='ProductSizePicker__SpaceRow']:only-child {
        display: block;
        p {
          width: max-content;
          display: block;
          font-family: $base-font-family;
          font-size: 14px;
          font-weight: normal;
          padding: 8px 12px;
          color: $color-black;
          background-color: $color-white;
          text-align: center;
          margin: 0 auto;
        }
      }
      .elc-size-picker-box-selected,
      .elc-size-picker-box {
        margin: 5px;
        border-radius: 0;
        width: auto;
        padding: 8px 12px;
      }
      .elc-size-picker-box {
        border: 1px solid $color-light-gray;
      }
      .elc-size-picker-box-selected {
        border: 3px solid $cr19-dark-pink;
        padding: 6px 8px;
        color: $color-black;
        background-color: $color-white;
        font-weight: 100;
        font-family: $header-font-bold;
      }
    }
    .elc-styled-product-image-container {
      .slick-slider {
        .elc-slider-arrow-wrapper {
          display: none;
          @include breakpoint($medium-up) {
            display: block;
          }
        }
        .elc-slider-dots-list {
          bottom: 10px;
        }
      }
    }
  }
}

.elc-stars-simplified:before,
.elc-stars-simplified-stars:before {
  background-size: $ratingSize;
  content: '';
  display: block;
  height: $ratingSize;
  position: absolute;
  top: 0;
  width: $ratingWidth;
}

.elc-stars-simplified {
  height: $ratingSize;
  position: relative;
  width: $ratingWidth;
  &:before {
    background: url('/sites/glamglowmud/themes/gg_base/img/icons/src/gg_star_grey.svg') repeat-x;
  }
  &-stars {
    &:before {
      background: url('/sites/glamglowmud/themes/gg_base/img/icons/src/gg_star_pink.svg') repeat-x;
    }
    display: block;
    height: $ratingSize;
    left: 0;
    position: absolute;
    overflow: hidden;
    width: $ratingWidth;
  }
}

// Notify me pop-up styling.
.elc-product-notify-me-pop-up-wrapper {
  display: flex;
  flex-direction: column;
  bottom: auto;
  top: 22%;
  #{$ldirection}: 50%;
  #{$rdirection}: 10%;
  .elc-product-notify-me-pop-up-close-button {
    background: none;
    padding: 5px;
    position: absolute;
    #{$rdirection}: 0;
  }
  .elc-product-notify-me-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    .elc-product-notify-me-title-label {
      @include main-font-bold;
      font-size: 22px;
      line-height: get-line-height(22px, 22px);
      text-transform: uppercase;
      width: 100%;
      text-align: center;
      margin: 13px;
      overflow-wrap: break-word;
      @include breakpoint($medium-up) {
        font-size: 30px;
      }
    }
    .elc-product-notify-me-description-label {
      @include main-font-book;
      font-size: 14px;
      line-height: get-line-height(14px, 29px);
      text-align: center;
      text-transform: uppercase;
      width: 100%;
      overflow-wrap: break-word;
      @include breakpoint($medium-up) {
        font-size: 18px;
        line-height: get-line-height(18px, 18px);
      }
    }
  }
  .elc-product-notify-me-form-wrapper {
    width: 100%;
    .elc-floating-label-string {
      display: none;
    }
    .elc-input-field {
      @include main-font-book;
      font-size: 14px;
      line-height: get-line-height(14px, 26px);
      height: 35px;
      border: none;
      border-bottom: 1px solid $color-lightgray;
      color: $color-black;
      padding: 5px;
      @include breakpoint($medium-up) {
        font-size: 18px;
        line-height: get-line-height(18px, 18px);
      }
    }
    .elc-input-error p {
      @include main-font-book;
      font-size: 14px;
      line-height: get-line-height(14px, 26px);
      color: $color-red;
      @include breakpoint($medium-up) {
        font-size: 18px;
        line-height: get-line-height(18px, 18px);
      }
    }
  }
  .elc-product-notify-me-notice-wrapper {
    order: 1;
    .elc-product-notify-me-notice {
      align-items: flex-end;
      padding: 10px 0;
      p {
        margin: 0;
      }
    }
    .elc-product-notify-me-gdpr-notice-label,
    .elc-product-notify-me-gdpr-read-confirmation {
      @include main-font-book;
      @include swap_direction(padding, 0 0 0 10px);
      font-size: 14px;
    }
    .elc-product-notify-me-gdpr-read-confirmation {
      padding: 0;
      color: $color-red;
    }
  }
  .elc-product-notify-me-info {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-flow: wrap;
    padding-top: 10px;
    order: 2;
    .elc-product-notify-me-info-your-data {
      @include main-font-book;
      font-size: 14px;
    }
    .elc-product-notify-me-info-button {
      @include swap_direction(padding, 0 10px 0 0);
      background: none;
    }
    .elc-product-gdpr-popup {
      display: none;
      position: relative;
      &.active {
        display: block;
        bottom: 0;
      }
    }
  }
  .elc-product-notify-me-submit-button {
    @include main-font-bold;
    background: none;
    background-color: $color-black;
    color: $color-white;
    height: 40px;
    padding: 0;
    &:hover {
      background: none;
      background-color: $color-lightgray;
      color: $color-black;
    }
  }
}

.elc-product-notify-me-confirmation-wrapper {
  display: flex;
  flex-direction: column;
  bottom: 22%;
  top: 22%;
  #{$ldirection}: 50%;
  #{$rdirection}: 10%;
  .elc-product-notify-me-confirmation-close-button {
    background: none;
    padding: 5px;
    position: absolute;
    #{$rdirection}: 0;
    top: 0;
  }
  .elc-product-notify-me-confirmation-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    max-width: none;
    height: 100%;
    padding: 90px 40px;
    @include breakpoint($medium-up) {
      padding: 110px 50px;
    }
    p {
      @include main-font-book;
      overflow-wrap: break-word;
    }
  }
  .elc-product-notify-me-confirmation-continue-button {
    @include main-font-book;
    position: relative;
    margin: 60px 0 0;
  }
  .elc-product-notify-me-confirmation-title {
    @include main-font-bold;
    font-size: 20px;
    line-height: get-line-height(20px, 25px);
    overflow: auto;
    margin: 30px 0 10px 0;
    text-transform: uppercase;
  }
}

.elc-shade-image-selected {
  &:after {
    // @todo remove important once react inline style will be removed
    width: 18px !important;
    height: 18px !important;
    box-shadow: $color-white 0 0 0 2px inset !important;
  }
}

// Moved from React inline style.
.elc-size-picker-container {
  .slick-track {
    display: flex;
    justify-content: center;
    width: inherit !important;
  }
}